<div class="tracks-background component inner-shadow-top" id="tracks">
    <div class="container">
        <div class="flex-container ">
            <div class="description-text">
                <div class="titles">Tracks</div>
                <div *ngIf="mobile; else desktopTemplate">
                    <app-read-more-box [fullText]=text></app-read-more-box>
                </div>


                <div>
                    <ng-template #desktopTemplate>
                    Tracks, tracks, tracks! You keep hearing about
                    them, but maybe you don’t really know what they
                    are. Let us explain! Tracks are different themes that
                    your project can be categorized under.
                    They will be
                    divided among the sponsors, who will also provide
                    the prizes and support for their specific track.
                    Your project can be eligible to receive awards for
                    one or more tracks, depending on the technologies
                    they use.
                    Pretty exciting, right?
                    </ng-template>
                </div>
            </div>
            <div class="width-40 track-data p-4">
                <div>
                    <div>
                        <div class="heading mt-sm-1">?</div>
                        <div class="subtitles mb-3 mb-md-5">technical tracks</div>
                    </div>
                    <div>
                        <div class="heading">5</div>
                        <div class="subtitles mb-3 mb-md-5">civic tracks</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
